import './ActivityRegistrationForm.css';
import React, {useEffect, useState} from "react";
import {
    getAdminEmail,
    printConsoleLog,
    redirectPage,
    showDialog,
    splitMessageContent,
    submitFormDataAsJsonString
} from "../../../utils/Utils";
import {
    ACTIVITY_REGISTRATION_ADMIN_MESSAGE_SUBJECT,
    ACTIVITY_REGISTRATION_ADMIN_TEMPLATE,
    ACTIVITY_REGISTRATION_MESSAGE_FROM,
    ACTIVITY_REGISTRATION_USER_MESSAGE_SUBJECT,
    ACTIVITY_REGISTRATION_USER_TEMPLATE
} from "../../../constants/forms/camp-register-form/ActivityRegistrationFormConstants";
import {useNavigate} from "react-router-dom";

export default function ActivityRegistrationForm() {

    const navigate = useNavigate();

    const useFetchData = () => {
        const [fetchedActivities, setFetchedActivities] = useState([]);
        const [fetchedLatestConsents, setFetchedLatestConsents] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const getAllOpenActivities = await fetch(`https://campego-api.vercel.app/api/v1/form/register/activities?status=active`, {
                        method: 'GET'
                    });

                    const latestConsents = await fetch(`https://campego-api.vercel.app/api/v1/form/latest-consents`, {
                        method: 'GET'
                    });

                    if (getAllOpenActivities.ok) {
                        const retrievedData = await getAllOpenActivities.json();
                        console.log(retrievedData);
                        setFetchedActivities(retrievedData);
                    } else {
                        console.error('Failed to fetch camps data from the API');
                    }

                    if (latestConsents.ok) {
                        const retrievedData = await latestConsents.json();
                        console.log(retrievedData);
                        setFetchedLatestConsents(retrievedData);
                    } else {
                        console.error('Failed to fetch recent rules data from the API');
                    }


                } catch (error) {
                    console.error('Error while fetching data:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }, []);

        return {fetchedActivities: fetchedActivities, fetchedLatestConsents: fetchedLatestConsents, isLoading};
    };

    const [numOfPeopleIn, setNumOfPeopleIn] = useState('');

    useEffect(() => {
        // Convert numOfPeopleIn to a number
        const numOfPeople = parseInt(numOfPeopleIn, 10);

        // Generate new camperInfoInputs based on numOfPeople
        const newCamperInfoInputs = Array.from({length: numOfPeople}, (_, index) => ({
            id: index + 1,
            firstName: '',
            lastName: '',
            TCKimlik: '',
            birthday: null,
            gender: '',
            occupation: '',
            vegetarianMenu: '',
            hasAllergy: '',
            allergyDetails: '',
            hasActivityRestriction: '',
            activityRestrictionDetails: '',
            hasNutritionRestriction: '',
            nutritionRestrictionDetails: '',
            hasMedicine: '',
            medicineDetails: [{name: '', potion: '', time: ''}],
            newDisease: '',
            fainted: '',
            heartDisease: '',
            otherDisease: '',
            medicalHistoryDetails: ''
        }));

        // Update the state with the new camperInfoInputs
        setCamperInfoInputs(newCamperInfoInputs);
    }, [numOfPeopleIn]);


    const {fetchedActivities, fetchedLatestConsents, isLoading} = useFetchData();

    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStayOption, setSelectedStayOption] = useState(null);
    const [selectedNumOfPeople, setSelectedNumOfPeople] = useState(null);


    const [camperInfoInputs, setCamperInfoInputs] = useState([
        {
            id: 1,
            firstName: '',
            lastName: '',
            TCKimlik: '',
            birthday: null,
            gender: '',
            occupation: '',
            vegetarianMenu: '',
            hasAllergy: '',
            allergyDetails: '',
            hasActivityRestriction: '',
            activityRestrictionDetails: '',
            hasNutritionRestriction: '',
            nutritionRestrictionDetails: '',
            hasMedicine: '',
            medicineDetails: [{name: '', potion: '', time: ''}],
            newDisease: '',
            fainted: '',
            heartDisease: '',
            otherDisease: '',
            medicalHistoryDetails: ''
        },
    ]);

    const [countryCode, setCountryCode] = useState('90'); // Set initial state to Türkiye's code
    const [phone, setPhone] = useState('');

    const [email, setEmail] = useState('');
    // const [address, setAddress] = useState('');
    const [country, setCountry] = useState('Türkiye');
    const [city, setCity] = useState('');
    const [town, setTown] = useState('');

    // const [emergencyContactName, setEmergencyContactName] = useState('');
    const [acilAramaCountryCode, setAcilAramaCountryCode] = useState('90');  // Set initial state to Türkiye's code
    const [acilAramaPhone, setAcilAramaPhone] = useState('');


    const [selectedNeredenDuydunuzOptions, setSelectedNeredenDuydunuzOptions] = useState([]);
    const [whatsAppGroupConfirmed, setWhatsAppGroupConfirmed] = useState('');

    // Açık Rıza Beyanı
    const [dataCollectionConsentId, setDataCollectionConsentId] = useState('');

    // Ticari Elektronik İleti Onay Beyanı
    const [marketingConsentId, setMarketingConsentId] = useState('');


    const handleActivityIdChange = (activityId) => {
        setSelectedActivityId(Number(activityId));
        setSelectedDate(null);
        setSelectedStayOption(null);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedStayOption(null);
        setSelectedNumOfPeople(null);
    };

    const handleNumOfPeopleChange = (concatenatedValue) => {
        const vArr = concatenatedValue.split("_--_");

        setSelectedNumOfPeople(vArr[0]);
        setNumOfPeopleIn(vArr[1]);
    };

    const handleFirstNameChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, firstName: value} : input
            )
        );
    };

    const handleLastNameChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, lastName: value} : input
            )
        );
    };

    const handleTCKimlikChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, TCKimlik: value} : input
            )
        );
    };

    const handleBirthdayChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, birthday: value} : input
            )
        );
    };

    const handleGenderChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, gender: value} : input
            )
        );
    };

    const handleOccupationChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, occupation: value} : input
            )
        );
    };

    const handleVegetarianMenuChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, vegetarianMenu: value} : input
            )
        );
    };

    const handleAllergyChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, hasAllergy: value, allergyDetails: ''} : input
            )
        );
    };

    const handleAllergyDetailsChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, allergyDetails: value} : input
            )
        );
    };

    const handleActivityRestrictionChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, hasActivityRestriction: value, activityRestrictionDetails: ''} : input
            )
        );
    };

    const handleActivityRestrictionDetailsChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, activityRestrictionDetails: value} : input
            )
        );
    };


    const handleNutritionRestrictionChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, hasNutritionRestriction: value, nutritionRestrictionDetails: ''} : input
            )
        );
    };

    const handleNutritionRestrictionDetailsChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, nutritionRestrictionDetails: value} : input
            )
        );
    };

    const handleHasMedicineChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, hasMedicine: value} : input
            )
        );
    };

    const handleMedicineChange = (id, index, key, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id
                    ? {
                        ...input,
                        medicineDetails: input.medicineDetails.map((med, i) =>
                            i === index ? {...med, [key]: value} : med
                        ),
                    }
                    : input
            )
        );
    };

    const handleAddMedicine = (id) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id
                    ? {
                        ...input,
                        medicineDetails: [...input.medicineDetails, {name: '', potion: '', time: ''}],
                    }
                    : input
            )
        );
    };

    const handleRemoveMedicine = (id, index) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id
                    ? {
                        ...input,
                        medicineDetails: input.medicineDetails.filter((_, i) => i !== index),
                    }
                    : input
            )
        );
    };

    // Handle function for "Yeni olmuş bir yara, hastalık veya temasla bulaşan hastalığı var mı?"
    const handleNewDiseaseChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, newDisease: value} : input
            )
        );
    };


// Handle function for "Şimdiye kadar egzersiz esnasında veya sonrasında baygınlık geçirdi mi?"
    const handleFaintedChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, fainted: value} : input
            )
        );
    };

// Handle function for "Şimdiye kadar herhangi bir kalp sıkıntısı teşhis edildi mi?"
    const handleHeartDiseaseChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, heartDisease: value} : input
            )
        );
    };

// Handle function for "Herhangi farklı bir hastalığı veya bu sorularla örtüşmeyen bir rahatsızlığı var mı?"
    const handleOtherDiseaseChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, otherDisease: value} : input
            )
        );
    };

    const handleMedicalHistoryDetailsChange = (id, value) => {
        setCamperInfoInputs((prevInputs) =>
            prevInputs.map((input) =>
                input.id === id ? {...input, medicalHistoryDetails: value} : input
            )
        );
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleTownChange = (event) => {
        setTown(event.target.value);
    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    const handlePhoneChange = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters
        const cleaned = input.replace(/\D/g, '');

        // Limit the input to 10 digits
        const slicedInput = cleaned.slice(0, 10);

        // Format the phone number dynamically
        let formattedPhone = '';

        for (let i = 0; i < slicedInput.length; i++) {
            if (i === 0) {
                formattedPhone += `(${slicedInput[i]}`;
            } else if (i === 2) {
                formattedPhone += `${slicedInput[i]}) `;
            } else if (i === 5) {
                formattedPhone += `${slicedInput[i]}-`;
            } else {
                formattedPhone += slicedInput[i];
            }
        }

        // Update the state with the dynamically formatted phone number
        setPhone(formattedPhone);
    };

    const handleKeyDown = (event) => {
        // Allow backspace (keyCode 8) to remove characters
        if (event.keyCode === 8) {
            setPhone("");
        }
    };

    const handleEmergencyCountryCodeChange = (event) => {
        setAcilAramaCountryCode(event.target.value);
    };

    const handleEmergencyPhoneChange = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters
        const cleaned = input.replace(/\D/g, '');

        // Limit the input to 10 digits
        const slicedInput = cleaned.slice(0, 10);

        // Format the phone number dynamically
        let formattedPhone = '';

        for (let i = 0; i < slicedInput.length; i++) {
            if (i === 0) {
                formattedPhone += `(${slicedInput[i]}`;
            } else if (i === 2) {
                formattedPhone += `${slicedInput[i]}) `;
            } else if (i === 5) {
                formattedPhone += `${slicedInput[i]}-`;
            } else {
                formattedPhone += slicedInput[i];
            }
        }

        // Update the state with the dynamically formatted phone number
        setAcilAramaPhone(formattedPhone);
    };

    const handleEmergencyPhoneKeyDown = (event) => {
        // Allow backspace (keyCode 8) to remove characters
        if (event.keyCode === 8) {
            setAcilAramaPhone("");
        }
    };

    const handleNeredenDuydunuzCheckboxChange = (option) => {
        if (selectedNeredenDuydunuzOptions.includes(option)) {
            // Remove the option if it's already selected
            setSelectedNeredenDuydunuzOptions(selectedNeredenDuydunuzOptions.filter((selectedOption) => selectedOption !== option));
        } else {
            // Add the option if it's not selected
            setSelectedNeredenDuydunuzOptions([...selectedNeredenDuydunuzOptions, option]);
        }
    };

    const handleWhatsAppGroupConfirmedChange = (event) => {
        const {value} = event.target;
        setWhatsAppGroupConfirmed(value === 'yes');
    };

    const handleDataCollectionConsentChange = (event) => {
        if (event.target.checked) {
            setDataCollectionConsentId(fetchedLatestConsents.dataCollectionConsent.consent_id);
        }
    };

    const handleMarketingConsentChange = (event) => {
        if (event.target.checked) {
            setMarketingConsentId(fetchedLatestConsents.marketingConsent.consent_id);
        }
    };

    const clearForm = () => {
        setSelectedActivityId(null);
        setSelectedDate(null);
        setSelectedStayOption(null);
        setCamperInfoInputs([{
            id: 1,
            firstName: '',
            lastName: '',
            TCKimlik: '',
            birthday: '',
            gender: '',
            occupation: '',
            vegetarianMenu: '',
            country: '',
            city: '',
            town: '',
            hasAllergy: '',
            allergyDetails: '',
            hasActivityRestriction: '',
            activityRestrictionDetails: '',
            hasNutritionRestriction: '',
            nutritionRestrictionDetails: '',
            hasMedicine: '',
            medicineDetails: [{name: '', potion: '', time: ''}],
            newDisease: '',
            fainted: '',
            heartDisease: '',
            otherDisease: '',
            medicalHistoryDetails: ''
        }]);
        setCountryCode("");
        setPhone("");
        setEmail("");
        // setAddress("");
        setCountry("");
        setCity("");
        // setEmergencyContactName("");
        setAcilAramaCountryCode("")
        setAcilAramaPhone("")
        setSelectedNeredenDuydunuzOptions(null)
        setWhatsAppGroupConfirmed("")
        setDataCollectionConsentId("")
        setMarketingConsentId("")
        setCountryCode("90");
    };

    function getTitleById(id) {
        const activity = fetchedActivities.find(activity => activity.id === id);
        return activity ? activity.title : null;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        showDialog("submitDialog");

        // Normalize
        const fullName = camperInfoInputs[0].firstName + " " + camperInfoInputs[0].lastName;
        const phoneFinal = '+' + countryCode + ' ' + phone;
        const emergencyPhoneFinal = '+' + acilAramaCountryCode + ' ' + acilAramaPhone;

        let participants = new Array(camperInfoInputs.length);

        for (let i = 0; i < camperInfoInputs.length; i++) {
            participants[i] = {
                id: camperInfoInputs[i].id,
                firstName: camperInfoInputs[i].firstName.trim(),
                lastName: camperInfoInputs[i].lastName.trim(),
                countryId: camperInfoInputs[i].TCKimlik.trim(),
                birthday: camperInfoInputs[i].birthday,
                gender: camperInfoInputs[i].gender,
                occupation: camperInfoInputs[i].occupation.trim(),
                phone: phoneFinal,
                email: email.trim(),
                city: town.trim(),
                state: city.trim(),
                country: country,
                emergencyPhone: emergencyPhoneFinal,
                whereDidYouHearUs: selectedNeredenDuydunuzOptions,
                whatsAppGroupConfirmed: whatsAppGroupConfirmed === true,
                paymentConsentId: null,
                dataCollectionConsentId: dataCollectionConsentId,
                marketingConsentId: marketingConsentId,
                vegetarianMenu: camperInfoInputs[i].vegetarianMenu,
                healthResponses: {
                    allergy: null, //camperInfoInputs[i].hasAllergy,
                    allergyDetails: null, // camperInfoInputs[i].allergyDetails,
                    activityRestriction: null, // camperInfoInputs[i].hasActivityRestriction,
                    activityRestrictionDetails: null, // camperInfoInputs[i].activityRestrictionDetails,
                    nutritionRestriction: null, // camperInfoInputs[i].hasNutritionRestriction,
                    nutritionRestrictionDetails: null, // camperInfoInputs[i].nutritionRestrictionDetails,
                    medicine: null, // camperInfoInputs[i].hasMedicine,
                    medicineDetails: null, // camperInfoInputs[i].medicineDetails[0].name === "" ? [] : camperInfoInputs[i].medicineDetails,
                    newlyOccurringDisease: camperInfoInputs[i].newDisease === true,
                    haveFaintedBefore: null, // camperInfoInputs[i].fainted,
                    heartDisease: null, // camperInfoInputs[i].heartDisease,
                    otherDisease: null, // camperInfoInputs[i].otherDisease,
                    medicalHistoryDetails: camperInfoInputs[i].medicalHistoryDetails
                }
            };
        }

        console.log(participants);

        // Create Payload
        const payload = {
            activity: {
                id: Number(selectedActivityId),
                title: getTitleById(Number(selectedActivityId)),
                date: selectedDate,
                numOfPeople: selectedNumOfPeople
            },
            email: {
                admin: {
                    from: ACTIVITY_REGISTRATION_MESSAGE_FROM,
                    to: getAdminEmail(),
                    subject: ACTIVITY_REGISTRATION_ADMIN_MESSAGE_SUBJECT(fullName),
                    template: ACTIVITY_REGISTRATION_ADMIN_TEMPLATE
                },
                user: {
                    from: ACTIVITY_REGISTRATION_MESSAGE_FROM,
                    to: email,
                    subject: ACTIVITY_REGISTRATION_USER_MESSAGE_SUBJECT,
                    template: ACTIVITY_REGISTRATION_USER_TEMPLATE
                }
            },
            participants: participants
        };

        console.log(payload);

        // Send data to API and get response
        const emailResponse =
            await submitFormDataAsJsonString(payload, '/email-sender/camp-register-email');

        const innerJson = JSON.parse(emailResponse.text);

        // Print response
        printConsoleLog(innerJson.status, innerJson.message);

        // Clear Form
        clearForm();

        // Redirect to relevant page
        if (innerJson.status === 200) {
            navigate('/camp-register-success', {state: {kkrnValue: innerJson.referenceCode}});
        } else {
            redirectPage('/failed');
        }
    };

    return (
        <>
            <div
                className="mt-3"
                style={{
                    maxWidth: '900px',
                    margin: '0 auto', // Centers the div horizontally
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                }}
            >
                <form onSubmit={handleSubmit} encType="multipart/form-data">

                    <div id="camp-information-section" className="mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">Aktivite Bilgileri</h2>
                        </div>
                        <p>
                            Lütfen hangi aktivite için kayıt oluşturduğunuzu ve diğer detayları seçiniz.
                        </p>

                        {isLoading ? (
                            <p>Lütfen bekleyiniz, aktivite bilgileri yükleniyor...</p>
                        ) : (

                            <>
                                <div>

                                    <div className="mb-3">
                                        <label htmlFor="formActivityId" className="form-label camp-burgundy required">
                                            Aktivite
                                        </label>
                                        <select
                                            id="formActivityId"
                                            className="form-select"
                                            onChange={(e) => handleActivityIdChange(e.target.value)}
                                            required
                                        >
                                            <option value="" hidden>
                                                Lütfen aktivite seçiniz...
                                            </option>
                                            {fetchedActivities.map((activity) => (
                                                <option key={activity.id} value={activity.id}>
                                                    {activity.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {selectedActivityId && (
                                        <div className="mb-3">
                                            <label htmlFor="formCampDay" className="form-label camp-burgundy required">
                                                Başlangıç ve Bitiş Tarihleri
                                            </label>
                                            <select
                                                id="formCampDay"
                                                className="form-select"
                                                onChange={(e) => handleDateChange(e.target.value)}
                                                required
                                            >
                                                <option value="" hidden>
                                                    Lütfen seçiniz...
                                                </option>
                                                {
                                                    (fetchedActivities.find((activity) => activity.id === Number(selectedActivityId)).date_options).map((dateOption) =>
                                                        dateOption.daysExt.map((dayExt, index) => {
                                                            // Using an if statement for conditional rendering
                                                            if (dateOption.status === 'active') {
                                                                return <option key={dayExt}
                                                                               value={dayExt}>{dayExt}</option>;
                                                            } else if (dateOption.status === 'full') {
                                                                return <option key={dayExt} value={dayExt}
                                                                               disabled>{dayExt} - Kontenjan
                                                                    Doldu</option>;
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    )
                                                }
                                            </select>
                                        </div>
                                    )}

                                    {selectedDate && (
                                        <div className="mb-3">
                                            <label htmlFor="formCampStay"
                                                   className="form-label camp-burgundy required">
                                                Kişi Sayısı
                                            </label>
                                            <select
                                                id="formCampNumOfPeople"
                                                className="form-select"
                                                onChange={(e) => handleNumOfPeopleChange(e.target.value)}
                                                required
                                            >
                                                <option value="" hidden>
                                                    Lütfen seçiniz...
                                                </option>
                                                {
                                                    (fetchedActivities.find((activity) => activity.id === Number(selectedActivityId)).participant_options).map((participantOption) =>
                                                        <option key={participantOption.opt}
                                                                value={participantOption.opt + '_--_' + participantOption.num}>{participantOption.opt}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    )}

                                </div>
                            </>
                        )}

                    </div>


                    <div className="mt-5">

                        {camperInfoInputs.map((camperInfoInput) => (

                            <div key={camperInfoInput.id}>

                                {camperInfoInput.id === 1 && (
                                    <div id="camper-information-section" className="mt-5">

                                        <div className="my-4 p-1">
                                            <h2 className="text-center text-secondary fw-bold">Katılımcı Detayları</h2>
                                        </div>
                                        <p>
                                            Lütfen aşağıdaki alanlara aktiviteye katılacak tüm bireylerin istenen
                                            bilgilerini
                                            yazınız.
                                        </p>

                                    </div>
                                )}

                                <div className="border border-5 border-grey rounded py-3 mb-3"
                                     style={{paddingRight: '5px', paddingLeft: '5px'}}>

                                    <div className="d-flex justify-content-between">
                                          <span className="h5">

                                              {camperInfoInput.id === 1 && (
                                                  <div>
                                                      <small>
                                                          <span className="badge bg-secondary">
                                                          Katılımcı <span>{camperInfoInput.id}</span> &nbsp;
                                                      </span>
                                                      </small>
                                                      <span className="text-grey" style={{fontSize: '12px'}}> ( Kayıt onay e-postası bu katılımcı adına
                                                          iletilecektir. )</span>
                                                  </div>
                                              )}

                                              {camperInfoInput.id !== 1 && (
                                                  <div>
                                                      <small>
                                                          <span className="badge bg-secondary">
                                                              Katılımcı <span>{camperInfoInput.id}</span>
                                                          </span>
                                                      </small>
                                                  </div>
                                              )}

                                          </span>

                                    </div>

                                    <div className="row mb-3">

                                        <div className="col-sm">
                                            <label htmlFor={`formFirstName${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Ad
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id={`formFirstName${camperInfoInput.id}`}
                                                name={`firstName${camperInfoInput.id}`}
                                                value={camperInfoInput.firstName}
                                                onChange={(e) => handleFirstNameChange(camperInfoInput.id, e.target.value)}
                                                maxLength="50"
                                                required
                                            />
                                        </div>

                                        <div className="col-sm">
                                            <label htmlFor={`formLastName${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Soyad
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id={`formLastName${camperInfoInput.id}`}
                                                name={`lastName${camperInfoInput.id}`}
                                                value={camperInfoInput.lastName}
                                                onChange={(e) => handleLastNameChange(camperInfoInput.id, e.target.value)}
                                                maxLength="50"
                                                required
                                            />
                                        </div>

                                    </div>

                                    <div className="row mb-3">

                                        <div className="col-sm-6">
                                            <label htmlFor={`formTCKimlik${camperInfoInput.id}`}
                                                   className="form-label">
                                                <span className="camp-burgundy required">T.C. Kimlik No</span>
                                            </label>
                                            <span style={{fontSize: '12px', color: "black"}}>
                                                    veya Pasaport No
                                                </span>
                                            <input
                                                className="form-control"
                                                type='text'
                                                id={`formTCKimlik${camperInfoInput.id}`}
                                                name={`TCKimlik${camperInfoInput.id}`}
                                                value={camperInfoInput.TCKimlik}
                                                onChange={(e) => handleTCKimlikChange(camperInfoInput.id, e.target.value)}
                                                maxLength="50"
                                                required
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <label htmlFor={`formBirthday${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Doğum Tarihi
                                            </label>
                                            <input
                                                className="form-control"
                                                type='date'
                                                id={`formBirthday${camperInfoInput.id}`}
                                                name={`birthday${camperInfoInput.id}`}
                                                value={camperInfoInput.birthday}
                                                onChange={(e) => handleBirthdayChange(camperInfoInput.id, e.target.value)}
                                                maxLength="4"
                                                required
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <label htmlFor={`formGender${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Cinsiyet
                                            </label>
                                            <select
                                                className="form-select"
                                                id={`formGender${camperInfoInput.id}`}
                                                name={`gender${camperInfoInput.id}`}
                                                value={camperInfoInput.gender}
                                                onChange={(e) => handleGenderChange(camperInfoInput.id, e.target.value)}
                                                required
                                            >
                                                <option value="" hidden></option>
                                                <option value="F">Kadın</option>
                                                <option value="M">Erkek</option>
                                                <option value="--">Belirtmek istemiyorum</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="row mb-3">


                                        <div className="col-sm">
                                            <label htmlFor={`formOccupation${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Meslek
                                            </label>
                                            <input
                                                className="form-control"
                                                type='text'
                                                id={`formOccupation${camperInfoInput.id}`}
                                                name={`occupation${camperInfoInput.id}`}
                                                value={camperInfoInput.occupation}
                                                onChange={(e) => handleOccupationChange(camperInfoInput.id, e.target.value)}
                                                maxLength="100"
                                                required
                                            />
                                        </div>

                                        <div className="col-sm">
                                            <label htmlFor={`formVegetarianMenu${camperInfoInput.id}`}
                                                   className="form-label camp-burgundy required">
                                                Vejetaryen menü istiyor musunuz?
                                            </label><br/>
                                            <select
                                                className="form-select"
                                                id={`formVegetarianMenu${camperInfoInput.id}`}
                                                name={`vegetarianMenu${camperInfoInput.id}`}
                                                value={camperInfoInput.vegetarianMenu}
                                                onChange={(e) => handleVegetarianMenuChange(camperInfoInput.id, e.target.value)}
                                                required
                                            >
                                                <option value="" hidden></option>
                                                <option value="true">Evet</option>
                                                <option value="false">Hayır</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div id="general-health-history-section" className="mt-2">

                                        <div className="mb-3">

                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" className="text-center">Evet</th>
                                                    <th scope="col" className="text-center">Hayır</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th scope="row">Yeni olmuş bir yara, hastalık veya temasla bulaşan
                                                        hastalığı var mı?
                                                    </th>
                                                    <td className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            id={`formNewDiseaseYes${camperInfoInput.id}`}
                                                            type="radio"
                                                            name={`newDisease${camperInfoInput.id}`}
                                                            value="yes"
                                                            checked={camperInfoInput.newDisease === true}
                                                            onChange={() => handleNewDiseaseChange(camperInfoInput.id, true)}
                                                            required
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            id={`formNewDiseaseNo${camperInfoInput.id}`}
                                                            type="radio"
                                                            name={`newDisease${camperInfoInput.id}`}
                                                            value="no"
                                                            checked={camperInfoInput.newDisease === false}
                                                            onChange={() => handleNewDiseaseChange(camperInfoInput.id, false)}
                                                            required
                                                        />
                                                    </td>
                                                </tr>

                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="mb-3" id="general-health-history-details">
                                            <label htmlFor="formAddress" className="form-label camp-burgundy">
                                                Genel Sağlık Geçmişi
                                            </label>&nbsp;&nbsp;
                                            <span style={{fontSize: '14px'}}>Bize iletmek istediğiniz bir bilgi var mı? (isteğe bağlı)</span>
                                            <textarea
                                                className="form-control"
                                                id={`formMedicalHistoryDetails${camperInfoInput.id}`}
                                                value={camperInfoInput.medicalHistoryDetails}
                                                name={`medicalHistoryDetails${camperInfoInput.id}`}
                                                onChange={(e) => handleMedicalHistoryDetailsChange(camperInfoInput.id, e.target.value)}
                                                rows="3"
                                            ></textarea>
                                        </div>

                                    </div>


                                    <div className="card text-dark bg-light mb-3">
                                        <div className="card-body">
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input"
                                                       id={`person_${camperInfoInput.id}_consent`}
                                                       required/>
                                                <label className="form-check-label"
                                                       htmlFor={`person_${camperInfoInput.id}_consent`}>
                                                    <span>Kampa
                                                    katılmak için
                                                    herhangi bir sağlık engelim</span>
                                                    <span style={{fontWeight: 'bold'}}> bulunmamaktadır</span>.
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>

                    <div id="camper-contact-section" className="mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">İletişim Bilgileri</h2>
                        </div>
                        <p>
                            Lütfen iletişim bilgilerinizi giriniz. Bir kişinin bilgileri yeterli olacaktır.
                        </p>

                        <div className="row mb-3">
                            <div className="col-sm-4">
                                <label htmlFor="formCountryCode" className="form-label camp-burgundy required">
                                    Ülke Kodu
                                </label>
                                <select
                                    className="form-select"
                                    id="formCountryCode"
                                    name="countryCode"
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    required
                                >
                                    <option value="" hidden>...</option>
                                    <option value="90" selected>Türkiye (+90)</option>
                                    <option value="213">Algeria (+213)</option>
                                    <option value="376">Andorra (+376)</option>
                                    <option value="244">Angola (+244)</option>
                                    <option value="1264">Anguilla (+1264)</option>
                                    <option value="1268">Antigua &amp; Barbuda (+1268)</option>
                                    <option value="54">Argentina (+54)</option>
                                    <option value="374">Armenia (+374)</option>
                                    <option value="297">Aruba (+297)</option>
                                    <option value="61">Australia (+61)</option>
                                    <option value="43">Austria (+43)</option>
                                    <option value="994">Azerbaijan (+994)</option>
                                    <option value="1242">Bahamas (+1242)</option>
                                    <option value="973">Bahrain (+973)</option>
                                    <option value="880">Bangladesh (+880)</option>
                                    <option value="1246">Barbados (+1246)</option>
                                    <option value="375">Belarus (+375)</option>
                                    <option value="32">Belgium (+32)</option>
                                    <option value="501">Belize (+501)</option>
                                    <option value="229">Benin (+229)</option>
                                    <option value="1441">Bermuda (+1441)</option>
                                    <option value="975">Bhutan (+975)</option>
                                    <option value="591">Bolivia (+591)</option>
                                    <option value="387">Bosnia Herzegovina (+387)</option>
                                    <option value="267">Botswana (+267)</option>
                                    <option value="55">Brazil (+55)</option>
                                    <option value="673">Brunei (+673)</option>
                                    <option value="359">Bulgaria (+359)</option>
                                    <option value="226">Burkina Faso (+226)</option>
                                    <option value="257">Burundi (+257)</option>
                                    <option value="855">Cambodia (+855)</option>
                                    <option value="237">Cameroon (+237)</option>
                                    <option value="1">Canada (+1)</option>
                                    <option value="238">Cape Verde Islands (+238)</option>
                                    <option value="1345">Cayman Islands (+1345)</option>
                                    <option value="236">Central African Republic (+236)</option>
                                    <option value="56">Chile (+56)</option>
                                    <option value="86">China (+86)</option>
                                    <option value="57">Colombia (+57)</option>
                                    <option value="269">Comoros (+269)</option>
                                    <option value="242">Congo (+242)</option>
                                    <option value="682">Cook Islands (+682)</option>
                                    <option value="506">Costa Rica (+506)</option>
                                    <option value="385">Croatia (+385)</option>
                                    <option value="53">Cuba (+53)</option>
                                    <option value="90392">Cyprus North (+90392)</option>
                                    <option value="357">Cyprus South (+357)</option>
                                    <option value="42">Czech Republic (+42)</option>
                                    <option value="45">Denmark (+45)</option>
                                    <option value="253">Djibouti (+253)</option>
                                    <option value="1809">Dominica (+1809)</option>
                                    <option value="1809">Dominican Republic (+1809)</option>
                                    <option value="593">Ecuador (+593)</option>
                                    <option value="20">Egypt (+20)</option>
                                    <option value="503">El Salvador (+503)</option>
                                    <option value="240">Equatorial Guinea (+240)</option>
                                    <option value="291">Eritrea (+291)</option>
                                    <option value="372">Estonia (+372)</option>
                                    <option value="251">Ethiopia (+251)</option>
                                    <option value="500">Falkland Islands (+500)</option>
                                    <option value="298">Faroe Islands (+298)</option>
                                    <option value="679">Fiji (+679)</option>
                                    <option value="358">Finland (+358)</option>
                                    <option value="33">France (+33)</option>
                                    <option value="594">French Guiana (+594)</option>
                                    <option value="689">French Polynesia (+689)</option>
                                    <option value="241">Gabon (+241)</option>
                                    <option value="220">Gambia (+220)</option>
                                    <option value="7880">Georgia (+7880)</option>
                                    <option value="49">Germany (+49)</option>
                                    <option value="233">Ghana (+233)</option>
                                    <option value="350">Gibraltar (+350)</option>
                                    <option value="30">Greece (+30)</option>
                                    <option value="299">Greenland (+299)</option>
                                    <option value="1473">Grenada (+1473)</option>
                                    <option value="590">Guadeloupe (+590)</option>
                                    <option value="671">Guam (+671)</option>
                                    <option value="502">Guatemala (+502)</option>
                                    <option value="224">Guinea (+224)</option>
                                    <option value="245">Guinea - Bissau (+245)</option>
                                    <option value="592">Guyana (+592)</option>
                                    <option value="509">Haiti (+509)</option>
                                    <option value="504">Honduras (+504)</option>
                                    <option value="852">Hong Kong (+852)</option>
                                    <option value="36">Hungary (+36)</option>
                                    <option value="354">Iceland (+354)</option>
                                    <option value="91">India (+91)</option>
                                    <option value="62">Indonesia (+62)</option>
                                    <option value="98">Iran (+98)</option>
                                    <option value="964">Iraq (+964)</option>
                                    <option value="353">Ireland (+353)</option>
                                    <option value="972">Israel (+972)</option>
                                    <option value="39">Italy (+39)</option>
                                    <option value="1876">Jamaica (+1876)</option>
                                    <option value="81">Japan (+81)</option>
                                    <option value="962">Jordan (+962)</option>
                                    <option value="7">Kazakhstan (+7)</option>
                                    <option value="254">Kenya (+254)</option>
                                    <option value="686">Kiribati (+686)</option>
                                    <option value="850">Korea North (+850)</option>
                                    <option value="82">Korea South (+82)</option>
                                    <option value="965">Kuwait (+965)</option>
                                    <option value="996">Kyrgyzstan (+996)</option>
                                    <option value="856">Laos (+856)</option>
                                    <option value="371">Latvia (+371)</option>
                                    <option value="961">Lebanon (+961)</option>
                                    <option value="266">Lesotho (+266)</option>
                                    <option value="231">Liberia (+231)</option>
                                    <option value="218">Libya (+218)</option>
                                    <option value="417">Liechtenstein (+417)</option>
                                    <option value="370">Lithuania (+370)</option>
                                    <option value="352">Luxembourg (+352)</option>
                                    <option value="853">Macao (+853)</option>
                                    <option value="389">Macedonia (+389)</option>
                                    <option value="261">Madagascar (+261)</option>
                                    <option value="265">Malawi (+265)</option>
                                    <option value="60">Malaysia (+60)</option>
                                    <option value="960">Maldives (+960)</option>
                                    <option value="223">Mali (+223)</option>
                                    <option value="356">Malta (+356)</option>
                                    <option value="692">Marshall Islands (+692)</option>
                                    <option value="596">Martinique (+596)</option>
                                    <option value="222">Mauritania (+222)</option>
                                    <option value="269">Mayotte (+269)</option>
                                    <option value="52">Mexico (+52)</option>
                                    <option value="691">Micronesia (+691)</option>
                                    <option value="373">Moldova (+373)</option>
                                    <option value="377">Monaco (+377)</option>
                                    <option value="976">Mongolia (+976)</option>
                                    <option value="1664">Montserrat (+1664)</option>
                                    <option value="212">Morocco (+212)</option>
                                    <option value="258">Mozambique (+258)</option>
                                    <option value="95">Myanmar (+95)</option>
                                    <option value="264">Namibia (+264)</option>
                                    <option value="674">Nauru (+674)</option>
                                    <option value="977">Nepal (+977)</option>
                                    <option value="31">Netherlands (+31)</option>
                                    <option value="687">New Caledonia (+687)</option>
                                    <option value="64">New Zealand (+64)</option>
                                    <option value="505">Nicaragua (+505)</option>
                                    <option value="227">Niger (+227)</option>
                                    <option value="234">Nigeria (+234)</option>
                                    <option value="683">Niue (+683)</option>
                                    <option value="672">Norfolk Islands (+672)</option>
                                    <option value="670">Northern Marianas (+670)</option>
                                    <option value="47">Norway (+47)</option>
                                    <option value="968">Oman (+968)</option>
                                    <option value="680">Palau (+680)</option>
                                    <option value="507">Panama (+507)</option>
                                    <option value="675">Papua New Guinea (+675)</option>
                                    <option value="595">Paraguay (+595)</option>
                                    <option value="51">Peru (+51)</option>
                                    <option value="63">Philippines (+63)</option>
                                    <option value="48">Poland (+48)</option>
                                    <option value="351">Portugal (+351)</option>
                                    <option value="1787">Puerto Rico (+1787)</option>
                                    <option value="974">Qatar (+974)</option>
                                    <option value="262">Reunion (+262)</option>
                                    <option value="40">Romania (+40)</option>
                                    <option value="7">Russia (+7)</option>
                                    <option value="250">Rwanda (+250)</option>
                                    <option value="378">San Marino (+378)</option>
                                    <option value="239">Sao Tome &amp; Principe (+239)</option>
                                    <option value="966">Saudi Arabia (+966)</option>
                                    <option value="221">Senegal (+221)</option>
                                    <option value="381">Serbia (+381)</option>
                                    <option value="248">Seychelles (+248)</option>
                                    <option value="232">Sierra Leone (+232)</option>
                                    <option value="65">Singapore (+65)</option>
                                    <option value="421">Slovak Republic (+421)</option>
                                    <option value="386">Slovenia (+386)</option>
                                    <option value="677">Solomon Islands (+677)</option>
                                    <option value="252">Somalia (+252)</option>
                                    <option value="27">South Africa (+27)</option>
                                    <option value="34">Spain (+34)</option>
                                    <option value="94">Sri Lanka (+94)</option>
                                    <option value="290">St. Helena (+290)</option>
                                    <option value="1869">St. Kitts (+1869)</option>
                                    <option value="1758">St. Lucia (+1758)</option>
                                    <option value="249">Sudan (+249)</option>
                                    <option value="597">Suriname (+597)</option>
                                    <option value="268">Swaziland (+268)</option>
                                    <option value="46">Sweden (+46)</option>
                                    <option value="41">Switzerland (+41)</option>
                                    <option value="963">Syria (+963)</option>
                                    <option value="886">Taiwan (+886)</option>
                                    <option value="7">Tajikstan (+7)</option>
                                    <option value="66">Thailand (+66)</option>
                                    <option value="228">Togo (+228)</option>
                                    <option value="676">Tonga (+676)</option>
                                    <option value="1868">Trinidad &amp; Tobago (+1868)</option>
                                    <option value="216">Tunisia (+216)</option>
                                    <option value="7">Turkmenistan (+7)</option>
                                    <option value="993">Turkmenistan (+993)</option>
                                    <option value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                    <option value="688">Tuvalu (+688)</option>
                                    <option value="256">Uganda (+256)</option>
                                    <option value="44">UK (+44)</option>
                                    <option value="380">Ukraine (+380)</option>
                                    <option value="971">United Arab Emirates (+971)</option>
                                    <option value="598">Uruguay (+598)</option>
                                    <option value="1">USA (+1)</option>
                                    <option value="7">Uzbekistan (+7)</option>
                                    <option value="678">Vanuatu (+678)</option>
                                    <option value="379">Vatican City (+379)</option>
                                    <option value="58">Venezuela (+58)</option>
                                    <option value="84">Vietnam (+84)</option>
                                    <option value="84">Virgin Islands - British (+1284)</option>
                                    <option value="84">Virgin Islands - US (+1340)</option>
                                    <option value="681">Wallis &amp; Futuna (+681)</option>
                                    <option value="969">Yemen (North)(+969)</option>
                                    <option value="967">Yemen (South)(+967)</option>
                                    <option value="260">Zambia (+260)</option>
                                    <option value="263">Zimbabwe (+263)</option>
                                </select>
                            </div>

                            <div className="col-sm-8">
                                <label htmlFor="formPhone" className="form-label camp-burgundy required">
                                    İletişim Numarası
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="(555) 555-5555"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    onKeyDown={handleKeyDown}
                                    required
                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="formEmail"
                                   className="form-label camp-burgundy required">
                                E-posta Adresi
                            </label><br/>
                            <small>
                                Konfirmasyon e-postası gönderilecektir. Lütfen güncel bir e-posta adresi giriniz.
                            </small>
                            <input
                                className="form-control"
                                type="email"
                                pattern="^(?!.*(<script|<iframe)\b)[\s\S]*$"
                                id="formEmail"
                                name="email"
                                onChange={handleEmailChange}
                                required
                            />
                        </div>


                        <div className="row mb-3">

                        </div>


                        <div className="row mb-3">

                            <div className="col-sm">
                                <label htmlFor="formCity" className="form-label camp-burgundy required">
                                    İlçe
                                </label>
                                <input
                                    className="form-control"
                                    type='text'
                                    id="formTown"
                                    name="town"
                                    value={town}
                                    onChange={handleTownChange}
                                    maxLength="50"
                                    required
                                />
                            </div>

                            <div className="col-sm">
                                <label htmlFor="formCity" className="form-label camp-burgundy required">
                                    Şehir
                                </label>
                                <input
                                    className="form-control"
                                    type='text'
                                    id="formCity"
                                    name="city"
                                    value={city}
                                    onChange={handleCityChange}
                                    maxLength="50"
                                    required
                                />
                            </div>

                            <div className="col-sm">
                                <label htmlFor="formCountry" className="form-label camp-burgundy required">
                                    Ülke
                                </label>
                                <select
                                    className="form-select"
                                    id="formCountry"
                                    name="country"
                                    value={country}
                                    onChange={handleCountryChange}
                                    required
                                >
                                    <option value="" hidden>Lütfen ülke seçiniz...</option>
                                    <option value="Türkiye">Türkiye</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory
                                    </option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic
                                        of The
                                    </option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald
                                        Islands
                                    </option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's
                                        Republic of
                                    </option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic
                                    </option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former
                                        Yugoslav Republic of
                                    </option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of
                                    </option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied
                                    </option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Helena">Saint Helena</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines
                                    </option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The
                                        South Sandwich Islands
                                    </option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-leste">Timor-leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying
                                        Islands
                                    </option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                        </div>


                    </div>

                    <div id="acil-aramalar-section" className="mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">Acil Aramalar</h2>
                        </div>
                        <p>
                            Lütfen acil durumlarda ulaşabileceğimiz, aktiviteye katılmayacak bir yakınınızın telefon
                            numarasını
                            yazınız.
                        </p>

                        <div className="row mb-3">
                            <div className="col-sm-4">
                                <label htmlFor="formAcilAramaCountryCode" className="form-label camp-burgundy required">
                                    Ülke Kodu
                                </label>
                                <select
                                    className="form-select"
                                    id="formAcilAramaCountryCode"
                                    name="acilAramaCountryCode"
                                    value={acilAramaCountryCode}
                                    onChange={handleEmergencyCountryCodeChange}
                                    required
                                >
                                    <option value="" hidden>...</option>
                                    <option value="90" selected>Türkiye (+90)</option>
                                    <option value="213">Algeria (+213)</option>
                                    <option value="376">Andorra (+376)</option>
                                    <option value="244">Angola (+244)</option>
                                    <option value="1264">Anguilla (+1264)</option>
                                    <option value="1268">Antigua &amp; Barbuda (+1268)</option>
                                    <option value="54">Argentina (+54)</option>
                                    <option value="374">Armenia (+374)</option>
                                    <option value="297">Aruba (+297)</option>
                                    <option value="61">Australia (+61)</option>
                                    <option value="43">Austria (+43)</option>
                                    <option value="994">Azerbaijan (+994)</option>
                                    <option value="1242">Bahamas (+1242)</option>
                                    <option value="973">Bahrain (+973)</option>
                                    <option value="880">Bangladesh (+880)</option>
                                    <option value="1246">Barbados (+1246)</option>
                                    <option value="375">Belarus (+375)</option>
                                    <option value="32">Belgium (+32)</option>
                                    <option value="501">Belize (+501)</option>
                                    <option value="229">Benin (+229)</option>
                                    <option value="1441">Bermuda (+1441)</option>
                                    <option value="975">Bhutan (+975)</option>
                                    <option value="591">Bolivia (+591)</option>
                                    <option value="387">Bosnia Herzegovina (+387)</option>
                                    <option value="267">Botswana (+267)</option>
                                    <option value="55">Brazil (+55)</option>
                                    <option value="673">Brunei (+673)</option>
                                    <option value="359">Bulgaria (+359)</option>
                                    <option value="226">Burkina Faso (+226)</option>
                                    <option value="257">Burundi (+257)</option>
                                    <option value="855">Cambodia (+855)</option>
                                    <option value="237">Cameroon (+237)</option>
                                    <option value="1">Canada (+1)</option>
                                    <option value="238">Cape Verde Islands (+238)</option>
                                    <option value="1345">Cayman Islands (+1345)</option>
                                    <option value="236">Central African Republic (+236)</option>
                                    <option value="56">Chile (+56)</option>
                                    <option value="86">China (+86)</option>
                                    <option value="57">Colombia (+57)</option>
                                    <option value="269">Comoros (+269)</option>
                                    <option value="242">Congo (+242)</option>
                                    <option value="682">Cook Islands (+682)</option>
                                    <option value="506">Costa Rica (+506)</option>
                                    <option value="385">Croatia (+385)</option>
                                    <option value="53">Cuba (+53)</option>
                                    <option value="90392">Cyprus North (+90392)</option>
                                    <option value="357">Cyprus South (+357)</option>
                                    <option value="42">Czech Republic (+42)</option>
                                    <option value="45">Denmark (+45)</option>
                                    <option value="253">Djibouti (+253)</option>
                                    <option value="1809">Dominica (+1809)</option>
                                    <option value="1809">Dominican Republic (+1809)</option>
                                    <option value="593">Ecuador (+593)</option>
                                    <option value="20">Egypt (+20)</option>
                                    <option value="503">El Salvador (+503)</option>
                                    <option value="240">Equatorial Guinea (+240)</option>
                                    <option value="291">Eritrea (+291)</option>
                                    <option value="372">Estonia (+372)</option>
                                    <option value="251">Ethiopia (+251)</option>
                                    <option value="500">Falkland Islands (+500)</option>
                                    <option value="298">Faroe Islands (+298)</option>
                                    <option value="679">Fiji (+679)</option>
                                    <option value="358">Finland (+358)</option>
                                    <option value="33">France (+33)</option>
                                    <option value="594">French Guiana (+594)</option>
                                    <option value="689">French Polynesia (+689)</option>
                                    <option value="241">Gabon (+241)</option>
                                    <option value="220">Gambia (+220)</option>
                                    <option value="7880">Georgia (+7880)</option>
                                    <option value="49">Germany (+49)</option>
                                    <option value="233">Ghana (+233)</option>
                                    <option value="350">Gibraltar (+350)</option>
                                    <option value="30">Greece (+30)</option>
                                    <option value="299">Greenland (+299)</option>
                                    <option value="1473">Grenada (+1473)</option>
                                    <option value="590">Guadeloupe (+590)</option>
                                    <option value="671">Guam (+671)</option>
                                    <option value="502">Guatemala (+502)</option>
                                    <option value="224">Guinea (+224)</option>
                                    <option value="245">Guinea - Bissau (+245)</option>
                                    <option value="592">Guyana (+592)</option>
                                    <option value="509">Haiti (+509)</option>
                                    <option value="504">Honduras (+504)</option>
                                    <option value="852">Hong Kong (+852)</option>
                                    <option value="36">Hungary (+36)</option>
                                    <option value="354">Iceland (+354)</option>
                                    <option value="91">India (+91)</option>
                                    <option value="62">Indonesia (+62)</option>
                                    <option value="98">Iran (+98)</option>
                                    <option value="964">Iraq (+964)</option>
                                    <option value="353">Ireland (+353)</option>
                                    <option value="972">Israel (+972)</option>
                                    <option value="39">Italy (+39)</option>
                                    <option value="1876">Jamaica (+1876)</option>
                                    <option value="81">Japan (+81)</option>
                                    <option value="962">Jordan (+962)</option>
                                    <option value="7">Kazakhstan (+7)</option>
                                    <option value="254">Kenya (+254)</option>
                                    <option value="686">Kiribati (+686)</option>
                                    <option value="850">Korea North (+850)</option>
                                    <option value="82">Korea South (+82)</option>
                                    <option value="965">Kuwait (+965)</option>
                                    <option value="996">Kyrgyzstan (+996)</option>
                                    <option value="856">Laos (+856)</option>
                                    <option value="371">Latvia (+371)</option>
                                    <option value="961">Lebanon (+961)</option>
                                    <option value="266">Lesotho (+266)</option>
                                    <option value="231">Liberia (+231)</option>
                                    <option value="218">Libya (+218)</option>
                                    <option value="417">Liechtenstein (+417)</option>
                                    <option value="370">Lithuania (+370)</option>
                                    <option value="352">Luxembourg (+352)</option>
                                    <option value="853">Macao (+853)</option>
                                    <option value="389">Macedonia (+389)</option>
                                    <option value="261">Madagascar (+261)</option>
                                    <option value="265">Malawi (+265)</option>
                                    <option value="60">Malaysia (+60)</option>
                                    <option value="960">Maldives (+960)</option>
                                    <option value="223">Mali (+223)</option>
                                    <option value="356">Malta (+356)</option>
                                    <option value="692">Marshall Islands (+692)</option>
                                    <option value="596">Martinique (+596)</option>
                                    <option value="222">Mauritania (+222)</option>
                                    <option value="269">Mayotte (+269)</option>
                                    <option value="52">Mexico (+52)</option>
                                    <option value="691">Micronesia (+691)</option>
                                    <option value="373">Moldova (+373)</option>
                                    <option value="377">Monaco (+377)</option>
                                    <option value="976">Mongolia (+976)</option>
                                    <option value="1664">Montserrat (+1664)</option>
                                    <option value="212">Morocco (+212)</option>
                                    <option value="258">Mozambique (+258)</option>
                                    <option value="95">Myanmar (+95)</option>
                                    <option value="264">Namibia (+264)</option>
                                    <option value="674">Nauru (+674)</option>
                                    <option value="977">Nepal (+977)</option>
                                    <option value="31">Netherlands (+31)</option>
                                    <option value="687">New Caledonia (+687)</option>
                                    <option value="64">New Zealand (+64)</option>
                                    <option value="505">Nicaragua (+505)</option>
                                    <option value="227">Niger (+227)</option>
                                    <option value="234">Nigeria (+234)</option>
                                    <option value="683">Niue (+683)</option>
                                    <option value="672">Norfolk Islands (+672)</option>
                                    <option value="670">Northern Marianas (+670)</option>
                                    <option value="47">Norway (+47)</option>
                                    <option value="968">Oman (+968)</option>
                                    <option value="680">Palau (+680)</option>
                                    <option value="507">Panama (+507)</option>
                                    <option value="675">Papua New Guinea (+675)</option>
                                    <option value="595">Paraguay (+595)</option>
                                    <option value="51">Peru (+51)</option>
                                    <option value="63">Philippines (+63)</option>
                                    <option value="48">Poland (+48)</option>
                                    <option value="351">Portugal (+351)</option>
                                    <option value="1787">Puerto Rico (+1787)</option>
                                    <option value="974">Qatar (+974)</option>
                                    <option value="262">Reunion (+262)</option>
                                    <option value="40">Romania (+40)</option>
                                    <option value="7">Russia (+7)</option>
                                    <option value="250">Rwanda (+250)</option>
                                    <option value="378">San Marino (+378)</option>
                                    <option value="239">Sao Tome &amp; Principe (+239)</option>
                                    <option value="966">Saudi Arabia (+966)</option>
                                    <option value="221">Senegal (+221)</option>
                                    <option value="381">Serbia (+381)</option>
                                    <option value="248">Seychelles (+248)</option>
                                    <option value="232">Sierra Leone (+232)</option>
                                    <option value="65">Singapore (+65)</option>
                                    <option value="421">Slovak Republic (+421)</option>
                                    <option value="386">Slovenia (+386)</option>
                                    <option value="677">Solomon Islands (+677)</option>
                                    <option value="252">Somalia (+252)</option>
                                    <option value="27">South Africa (+27)</option>
                                    <option value="34">Spain (+34)</option>
                                    <option value="94">Sri Lanka (+94)</option>
                                    <option value="290">St. Helena (+290)</option>
                                    <option value="1869">St. Kitts (+1869)</option>
                                    <option value="1758">St. Lucia (+1758)</option>
                                    <option value="249">Sudan (+249)</option>
                                    <option value="597">Suriname (+597)</option>
                                    <option value="268">Swaziland (+268)</option>
                                    <option value="46">Sweden (+46)</option>
                                    <option value="41">Switzerland (+41)</option>
                                    <option value="963">Syria (+963)</option>
                                    <option value="886">Taiwan (+886)</option>
                                    <option value="7">Tajikstan (+7)</option>
                                    <option value="66">Thailand (+66)</option>
                                    <option value="228">Togo (+228)</option>
                                    <option value="676">Tonga (+676)</option>
                                    <option value="1868">Trinidad &amp; Tobago (+1868)</option>
                                    <option value="216">Tunisia (+216)</option>
                                    <option value="7">Turkmenistan (+7)</option>
                                    <option value="993">Turkmenistan (+993)</option>
                                    <option value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                    <option value="688">Tuvalu (+688)</option>
                                    <option value="256">Uganda (+256)</option>
                                    <option value="44">UK (+44)</option>
                                    <option value="380">Ukraine (+380)</option>
                                    <option value="971">United Arab Emirates (+971)</option>
                                    <option value="598">Uruguay (+598)</option>
                                    <option value="1">USA (+1)</option>
                                    <option value="7">Uzbekistan (+7)</option>
                                    <option value="678">Vanuatu (+678)</option>
                                    <option value="379">Vatican City (+379)</option>
                                    <option value="58">Venezuela (+58)</option>
                                    <option value="84">Vietnam (+84)</option>
                                    <option value="84">Virgin Islands - British (+1284)</option>
                                    <option value="84">Virgin Islands - US (+1340)</option>
                                    <option value="681">Wallis &amp; Futuna (+681)</option>
                                    <option value="969">Yemen (North)(+969)</option>
                                    <option value="967">Yemen (South)(+967)</option>
                                    <option value="260">Zambia (+260)</option>
                                    <option value="263">Zimbabwe (+263)</option>
                                </select>
                            </div>

                            <div className="col-sm-8">
                                <label htmlFor="formAcilAramaPhone" className="form-label camp-burgundy required">
                                    İletişim Numarası
                                </label>
                                <input
                                    className="form-control"
                                    id="formAcilAramaPhone"
                                    type="text"
                                    placeholder="(555) 555-5555"
                                    value={acilAramaPhone}
                                    onChange={handleEmergencyPhoneChange}
                                    onKeyDown={handleEmergencyPhoneKeyDown}
                                    required
                                />
                            </div>
                        </div>

                    </div>

                    <div id="bizi-nereden-duydunuz-section" className="mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">
                                Anket
                            </h2>
                        </div>

                        <label htmlFor="neredenDuydunuzSoru" className="form-label camp-burgundy required">
                            Aktivitemizi nereden duydunuz?
                        </label><br/>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="campego.tr Instagram sayfasını takip ediyordum"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('campego.tr Instagram sayfasını takip ediyordum')}
                                />
                                &nbsp;&nbsp;campego.tr Instagram sayfasını takip ediyordum
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Instagram'da reklam olarak çıktı"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('Instagram\'da reklam olarak çıktı')}
                                />
                                &nbsp;&nbsp;Instagram'da reklam olarak çıktı
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Google aratmalarında fark ettim"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('Google aratmalarında fark ettim')}
                                />
                                &nbsp;&nbsp;Google aratmalarında fark ettim
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="www.campegocamp.com internet sayfasından"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('www.campegocamp.com internet sayfasından')}
                                />
                                &nbsp;&nbsp;www.campegocamp.com internet sayfasından
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Arkadaş tavsiyesi"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('Arkadaş tavsiyesi')}
                                />
                                &nbsp;&nbsp;Arkadaş tavsiyesi
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Aktivite alanının/otelin sosyal medya sayfasından"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('Aktivite alanının/otelin sosyal medya sayfasından')}
                                />
                                &nbsp;&nbsp;Kamp alanının/otelin sosyal medya sayfasından
                            </label>
                        </div>

                        <div className="mb-3">
                            <label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="Diğer"
                                    onChange={() => handleNeredenDuydunuzCheckboxChange('Diğer')}
                                />
                                &nbsp;&nbsp;Diğer
                            </label>
                        </div>

                    </div>

                    <div id="iletisim-grubu-section" className="mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">
                                İletişim Grubu
                            </h2>
                        </div>

                        <label htmlFor="whatsappSoru" className="form-label camp-burgundy required">
                            Aktivitede iletişimi daha sağlıklı sağlamak amacıyla, aktivite öncesinde kurulacak olan
                            WhatsApp
                            grubuna dahil olmak ister misiniz?
                        </label><br/>

                        <div className="row">

                            <div className="col-sm">

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="yes"
                                        checked={whatsAppGroupConfirmed === true}
                                        onChange={handleWhatsAppGroupConfirmedChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        <span className="fw-bold"> Evet</span>, WhatsApp grubuna katılmak isterim.
                                    </label>
                                </div>

                            </div>

                            <div className="col-sm">

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value="no"
                                        checked={whatsAppGroupConfirmed === false}
                                        onChange={handleWhatsAppGroupConfirmedChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        <span className="fw-bold"> Hayır</span>, WhatsApp grubuna katılmak istemiyorum.
                                    </label>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div id="suggestions-section" className="mb-3 mt-5">

                        <div className="my-4 p-1">
                            <h2 className="text-center text-secondary fw-bold">
                                Kamp Aktivitesi İçin Öneriler
                            </h2>
                        </div>

                        <div className="mb-5">
                            <div className="accordion mb-3" id="accordionExample4">
                                <div className="accordion-item">
                                    <div id="collapseFour" className="accordion-collapse collapse show"
                                         data-bs-parent="#accordionExample4">
                                        <div className="accordion-body">
                                            <ul>
                                                <li>
                                                    Matara (su kabı)
                                                </li>
                                                <li>
                                                    Küçük sırt çantası (tercihen belden bağlamalı)
                                                </li>
                                                <li>
                                                    Yağmurluk (Hava durumuna göre)
                                                </li>
                                                <li>
                                                    Mevsime uygun giysi (Tüm kamplarımızda ceket, hırka getirmenizi
                                                    öneririz.)
                                                </li>
                                                <li>
                                                    Yedek rahat pantolon/eşofman
                                                </li>
                                                <li>
                                                    Trekking botu veya spor ayakkabısı
                                                </li>
                                                <li>
                                                    Mevsime göre şapka/bere
                                                </li>
                                                <li>
                                                    Güneş gözlüğü
                                                </li>
                                                <li>
                                                    Deniz Şortu/Mayo (Hava durumuna ve bölgeye göre)
                                                </li>
                                                <li>
                                                    Küçük not defteri ve kurşun kalem(not almak isteyebileceğiniz şeyler
                                                    olabilir)
                                                </li>
                                                <li>
                                                    Kişisel temizlik malzemeleri
                                                </li>
                                            </ul>
                                            <br/>
                                            <p>
                                                Mevsime, hava koşullarına ve kamp bölgesine göre malzeme seçimine dikkat
                                                ediniz.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="consent-section" className="mb-3 mt-5">

                        <div className="mb-5">
                            <div className="accordion mb-3" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            <span> Açık Rıza Beyanı</span>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                         data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            (Formu dolduran katılımcının, kendisiyle birlikte gelen kişiler adına da
                                            (eğer varsa) işaretleme yaptığı kabul edilecektir.)
                                            <br/><br/>

                                            {fetchedLatestConsents && fetchedLatestConsents.dataCollectionConsent
                                                ? splitMessageContent(fetchedLatestConsents.dataCollectionConsent.text).map((line, index) => (
                                                    <p key={index}>{line}</p>
                                                ))
                                                : <p> Açık Rıza Beyanı metni yükleniyor...</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-check mx-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formInformedConsent"
                                    name="informedConsent"
                                    onChange={handleDataCollectionConsentChange}
                                    required
                                />
                                <label className="form-check-label" htmlFor="formElectronicMessageConsent">
                                    <span> Açık Rıza Beyanı metnini okudum, anladım ve kabul ediyorum. </span>
                                </label>
                            </div>
                        </div>


                        <div className="mb-3 mb-5">
                            <div className="accordion mb-3" id="accordionExample2">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="true"
                                                aria-controls="collapseTwo">
                                            <span>Ticari Elektronik İleti Onay Beyanı</span>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show"
                                         data-bs-parent="#accordionExample2">
                                        <div className="accordion-body">
                                            (Formu dolduran katılımcının, kendisiyle birlikte gelen kişiler adına da
                                            (eğer varsa) işaretleme yaptığı kabul edilecektir.)
                                            <br/><br/>

                                            {fetchedLatestConsents && fetchedLatestConsents.marketingConsent
                                                ? splitMessageContent(fetchedLatestConsents.marketingConsent.text).map((line, index) => (
                                                    <p key={index}>{line}</p>
                                                ))
                                                : <p> Ticari Elektronik İleti Onay Beyanı metni yükleniyor...</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-check mx-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formElectronicMessageConsent"
                                    name="electronicMessageConsent"
                                    onChange={handleMarketingConsentChange}
                                    required
                                />
                                <label className="form-check-label" htmlFor="formElectronicMessageConsent">
                                    <span>Ticari Elektronik İleti Onay Beyanı metnini okudum, anladım ve kabul ediyorum.</span>
                                </label>
                            </div>
                        </div>

                        <div className="mb-3 mb-5">

                            <div className="form-check mx-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    required
                                />
                                <label className="form-check-label">
                                    Yukarıda verdiğim bilgiler doğrudur. Aksi halde oluşabilecek olumsuz durumları kabul
                                    ediyorum.
                                </label>
                            </div>

                        </div>

                    </div>

                    <div id="submit-button-section" className="mb-3 mt-5">
                        {/* Submit Button */}
                        <button type="submit" className="btn camp-btn-primary">Gönder</button>
                    </div>

                </form>
            </div>


            <dialog id="submitDialog" className="rounded">
                <div id="loader" className="align-items-center justify-content-center text-center">
                    <div id="dialogSpinner" className="spinner-border m-5 text-center" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p id="dialogText">Lütfen bekleyiniz.</p>
                    <p id="dialogText">Aktivite kayıt bilgileriniz iletiliyor...</p>
                </div>
            </dialog>

        </>
    )
}