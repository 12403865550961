export const ACTIVITY_REGISTRATION_MESSAGE_FROM = "Aktivite Kayıt Formu - Campego";

export const ACTIVITY_REGISTRATION_ADMIN_MESSAGE_SUBJECT = (fullName) => `Aktivite Kayıt Formu : ${fullName}`;
export const ACTIVITY_REGISTRATION_USER_MESSAGE_SUBJECT  = "Teşekkürler";

export const ACTIVITY_REGISTRATION_ADMIN_TEMPLATE = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>Yeni Mesaj</title>
  </head>
  <body
    style="
      background-color: #eeeeee;
      color: #666666;
      font-family: Lato, Tahoma, sans-serif;
    "
  >
    <div style="background-color: #eeeeee; padding: 20px 10px">
      <table
        style="
          background-color: #ffffff;
          margin: 0 auto;
          max-width: 600px;
          min-width: 320px;
          width: 100%;
          border-collapse: collapse;
        "
      >
        <tr>
          <td style="padding: 0; text-align: center">
            <a
              href="https://www.campegocamp.com/"
              style="text-decoration: none !important"
              target="_blank"
            >
              <img
                alt=""
                src="https://lh3.googleusercontent.com/pw/AJFCJaWoB5LnOYc-ykrBEqgN-xvzE1mrcwbhenXgFoA1or2ayyVC7568zQ-tpYvgOVw0Bsdu3F_BtIYHyHpYX7tNedcpzKVH186xCGpS8-siCcWFOii7Jw=w2400"
                style="
                  outline: none;
                  text-decoration: none;
                  -ms-interpolation-mode: bicubic;
                  border: none;
                  max-width: 100%;
                  font-size: 16px;
                "
                width="200"
              />
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div style="padding: 20px">
              <p style="font-size: 5px">&nbsp;</p>

              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                Yeni bir mesajınız var!
              </p>

              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                <span style="color: #840c0c">Form : </span> {{_FORM_NAME_}}
              </p>

              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                <span style="color: #840c0c">Kamp Adı: </span> {{_CAMP_NAME_}}
              </p>
              
              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                <span style="color: #840c0c">Gönderen : </span> {{_CAMPER_FULL_NAME_}}
              </p>
              
              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                <span style="color: #840c0c">Telefon : </span> {{_CAMPER_PHONE_}}
              </p>
              
              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                <span style="color: #840c0c">Referans Kodu : </span> {{_REFERENCE_CODE_}}
              </p>

              <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                Formu detaylı görmek için yönetici kontrol panelini ziyaret ediniz.
              </p>

              <p style="font-size: 10px">&nbsp;</p>

              <p
                style="
                  font-size: 18px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                  color: #840c0c;
                "
              >
                Campego
              </p>
              <p
                style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                "
              >
                Psikoloji/Gelişim Temelli
              </p>
              <p
                style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                "
              >
                Doğa Kampları
              </p>

              <div style="text-align: center">
                <a
                  href="https://app.campegocamp.com/"
                  target="_blank"
                  style="
                    background-color: #840c0c;
                    color: white;
                    padding: 12px 24px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    border-radius: 8px;
                    font-weight: bold;
                    font-size: 15px;
                  "
                  >Yönetici Kontrol Paneli'ne Git
                </a>
              </div>
              <p style="font-size: 10px">&nbsp;</p>
            </div>
          </td>
        </tr>
      </table>

      <table
        style="
          background-color: #ffffff;
          margin: 0 auto;
          max-width: 600px;
          min-width: 320px;
          width: 100%;
          border-collapse: collapse;
        "
      >
        <tr>
          <td
            style="
              background-color: #073763;
              color: #ffffff;
              text-align: center;
            "
          >
            <p style="font-size: 10px">&nbsp;</p>

            <div style="display: block; text-align: center">
              <a
                href="https://www.instagram.com/campego.tr/"
                style="color: #00a4bd; text-decoration: none !important"
                data-hs-link-id="0"
                target="_blank"
              >
                <img
                  alt="instagram"
                  src="https://lh3.googleusercontent.com/po7MWtnxQCvrR5qqqeTz84zFB6DkBqGLH0xyTkRVkeiX1do5GXG-X72kWyse2c0FsChj51TFzROwZ8G9gjXzxTTv7cike4rK2L8czR8EGhspHzadM7nNZPSItzmI8rt5XbgMuIb2=s32-p-k"
                /> </a
              >&nbsp;&nbsp;&nbsp;

              <a
                href="https://www.instagram.com/campego.tr/"
                style="color: #00a4bd; text-decoration: none !important"
                data-hs-link-id="0"
                target="_blank"
              >
                <img
                  alt="linkedin"
                  src="https://lh3.googleusercontent.com/YsOwU7uGGhnEMEinSchp06zP8GtSG6awL5jSRSqw3WKmS_S6zh5fMktXDGu7sOqpHiZjgvnlAiHKXuFeT3IPCM9AbS6Y8HHwaMCjMGLZSIDOhw4AxL_-3QhYw0ZM3tbLdbQC1cR1=s32-p-k"
                /> </a
              >&nbsp;&nbsp;&nbsp;

              <a
                href="https://www.instagram.com/campego.tr/"
                style="color: #00a4bd; text-decoration: none !important"
                data-hs-link-id="0"
                target="_blank"
              >
                <img
                  alt="twitter"
                  src="https://lh3.googleusercontent.com/agii81skX0K4dXxR9RNHptWaynz2nTJMDoLGmP7EmlUcqXQPhMRcvRSZa8Yz7Fs5d35yG25Y6a2gfcqcJWATOngtmEBXuC1wDsb7H6YBUfgK0YMU92jS2faDOz0zLmBruoFNohh6=s32-p-k"
                />
              </a>
            </div>

            <div style="text-align: center; width: 60%; display: inline-block">
              <p style="font-size: 5px; border-bottom: 1px solid grey">
                &nbsp;
              </p>
            </div>

            <p style="font-size: 3px">&nbsp;</p>
            <p
              style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
            >
              Campego
            </p>
            <p
              style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
            >
              Psikoloji/Gelişim Temelli Doğa Kampları
            </p>
            <p
              style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
            >
              © {{_CURRENT_YEAR_}}
            </p>
            <p
              style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
            >
              Tüm Hakları Saklıdır.
            </p>
            <p style="font-size: 10px">&nbsp;</p>
          </td>
        </tr>
      </table>
    </div>
  </body>
</html>
`;

export const ACTIVITY_REGISTRATION_USER_TEMPLATE = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>Teşekkürler</title>
</head>
<body
        style="
      background-color: #eeeeee;
      color: #666666;
      font-family: Lato, Tahoma, sans-serif;
    "
>
<div style="background-color: #eeeeee; padding: 20px 10px">
    <table
            style="
          background-color: #ffffff;
          margin: 0 auto;
          max-width: 600px;
          min-width: 320px;
          width: 100%;
          border-collapse: collapse;
        "
    >
        <tr>
            <td style="padding: 0; text-align: center">
                <a
                        href="https://www.campegocamp.com/"
                        style="text-decoration: none !important"
                        target="_blank"
                >
                    <img
                            alt=""
                            src="https://lh3.googleusercontent.com/pw/AJFCJaWoB5LnOYc-ykrBEqgN-xvzE1mrcwbhenXgFoA1or2ayyVC7568zQ-tpYvgOVw0Bsdu3F_BtIYHyHpYX7tNedcpzKVH186xCGpS8-siCcWFOii7Jw=w2400"
                            style="
                  outline: none;
                  text-decoration: none;
                  -ms-interpolation-mode: bicubic;
                  border: none;
                  max-width: 100%;
                  font-size: 16px;
                "
                            width="200"
                    />
                </a>
            </td>
        </tr>
        <tr>
            <td>
                <div style="padding: 20px">
                    <p style="font-size: 5px">&nbsp;</p>

                    <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                        Sevgili <span style="color: #840c0c">{{_FIRST_NAME_}}</span>,
                    </p>

                    <p style="font-size: 10px">&nbsp;</p>

                    <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                        Aktivite Kayıt Formunuzu aldığımızı size bildirmek isteriz.
                    </p>

                    <p style="font-size: 10px">&nbsp;</p>

                    <p
                            style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                  color: #840c0c;
                "
                    >
                        Kayıt Referans Kodu
                    </p>

                    <p
                            style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                  color: #073763;
                "
                    >
                        {{_KKRN_}}
                    </p>

                    <p style="font-size: 10px">&nbsp;</p>

                    <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                        Herhangi bir soru ve talebiniz var ise, lütfen
                        <a
                                href="https://www.campegocamp.com/bize-ula%C5%9F%C4%B1n"
                                target="_blank"
                                style="color: #840c0c"
                        >İletişim Formu</a
                        >
                        üzerinden bizimle iletişime geçmekten çekinmeyiniz :)
                    </p>

                    <p style="font-size: 15px; line-height: 1.5em; font-weight: bold">
                        En iyi dileklerimizle,
                    </p>

                    <p style="font-size: 10px">&nbsp;</p>

                    <p
                            style="
                  font-size: 18px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                  color: #840c0c;
                "
                    >
                        Campego
                    </p>
                    <p
                            style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                "
                    >
                        Psikoloji/Gelişim Temelli
                    </p>
                    <p
                            style="
                  font-size: 15px;
                  line-height: 1.5em;
                  font-weight: bold;
                  text-align: center;
                "
                    >
                        Doğa Kampları
                    </p>

                    <div style="text-align: center">
                        <a
                                href="https://www.campegocamp.com/"
                                target="_blank"
                                style="
                    background-color: #840c0c;
                    color: white;
                    padding: 12px 24px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    border-radius: 8px;
                    font-weight: bold;
                    font-size: 15px;
                  "
                        >Web sitemizi ziyaret edin
                        </a>
                    </div>
                    <p style="font-size: 10px">&nbsp;</p>
                </div>
            </td>
        </tr>
    </table>

    <table
            style="
          background-color: #ffffff;
          margin: 0 auto;
          max-width: 600px;
          min-width: 320px;
          width: 100%;
          border-collapse: collapse;
        "
    >
        <tr>
            <td
                    style="
              background-color: #073763;
              color: #ffffff;
              text-align: center;
            "
            >
                <p style="font-size: 10px">&nbsp;</p>

                <div style="display: block; text-align: center">
                    <a
                            href="https://www.instagram.com/campego.tr/"
                            style="color: #00a4bd; text-decoration: none !important"
                            data-hs-link-id="0"
                            target="_blank"
                    >
                        <img
                                alt="instagram"
                                src="https://lh3.googleusercontent.com/po7MWtnxQCvrR5qqqeTz84zFB6DkBqGLH0xyTkRVkeiX1do5GXG-X72kWyse2c0FsChj51TFzROwZ8G9gjXzxTTv7cike4rK2L8czR8EGhspHzadM7nNZPSItzmI8rt5XbgMuIb2=s32-p-k"
                        /> </a
                    >&nbsp;&nbsp;&nbsp;

                    <a
                            href="https://www.instagram.com/campego.tr/"
                            style="color: #00a4bd; text-decoration: none !important"
                            data-hs-link-id="0"
                            target="_blank"
                    >
                        <img
                                alt="linkedin"
                                src="https://lh3.googleusercontent.com/YsOwU7uGGhnEMEinSchp06zP8GtSG6awL5jSRSqw3WKmS_S6zh5fMktXDGu7sOqpHiZjgvnlAiHKXuFeT3IPCM9AbS6Y8HHwaMCjMGLZSIDOhw4AxL_-3QhYw0ZM3tbLdbQC1cR1=s32-p-k"
                        /> </a
                    >&nbsp;&nbsp;&nbsp;

                    <a
                            href="https://www.instagram.com/campego.tr/"
                            style="color: #00a4bd; text-decoration: none !important"
                            data-hs-link-id="0"
                            target="_blank"
                    >
                        <img
                                alt="twitter"
                                src="https://lh3.googleusercontent.com/agii81skX0K4dXxR9RNHptWaynz2nTJMDoLGmP7EmlUcqXQPhMRcvRSZa8Yz7Fs5d35yG25Y6a2gfcqcJWATOngtmEBXuC1wDsb7H6YBUfgK0YMU92jS2faDOz0zLmBruoFNohh6=s32-p-k"
                        />
                    </a>
                </div>

                <div style="text-align: center; width: 60%; display: inline-block">
                    <p style="font-size: 5px; border-bottom: 1px solid grey">
                        &nbsp;
                    </p>
                </div>

                <p style="font-size: 3px">&nbsp;</p>
                <p
                        style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
                >
                    Campego
                </p>
                <p
                        style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
                >
                    Psikoloji/Gelişim Temelli Doğa Kampları
                </p>
                <p
                        style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
                >
                    © {{_CURRENT_YEAR_}}
                </p>
                <p
                        style="
                font-size: 10px;
                line-height: 1.5em;
                font-weight: bold;
                text-align: center;
              "
                >
                    Tüm Hakları Saklıdır.
                </p>
                <p style="font-size: 10px">&nbsp;</p>
            </td>
        </tr>
    </table>
</div>
</body>
</html>
`;