import './App.css';
import {Route, Routes} from 'react-router-dom';
import ContactForm from "./components/forms/contact-form/ContactForm";
import CareerForm from "./components/forms/career-form/CareerForm";
import Home from "./components/home/Home";
import ActivityRegistrationForm from "./components/forms/activity-registration-form/ActivityRegistrationForm";
import Success from "./components/success/Success";
import Failed from "./components/failed/Failed";
import CampRegisterSuccess from "./components/success/CampRegisterSuccess";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/contact-form" element={<ContactForm/>}/>
                <Route path="/career-form" element={<CareerForm/>}/>
                <Route path="/activity-registration-form" element={<ActivityRegistrationForm/>}/>
                <Route path="/success" element={<Success/>}/>
                <Route path="/camp-register-success" element={<CampRegisterSuccess/>}/>
                <Route path="/failed" element={<Failed/>}/>
            </Routes>
        </div>
    );
}

export default App;
